<template>
    <div class="container terms">
        <div id="article">
            <strong class="tit_article">이용정지 행위 안내 및 처리 규정</strong>
            <br>
            <br>헤이비글은 의뢰자,전문가,헤이비글 3자간 상호 신뢰를 바탕으로 상생할 수 있는 생태계를 만들기 위해 많은 인적, 기술적, 금전적 투입과 노력을 하고 있습니다.
            <br>이에 그 노력 및 취지에 어긋나거나 해하는 행위를 하는 경우 아래의 규정에 따라 서비스 이용 제한 등의 조치를 취할 수 있습니다.
            <br><br><br>
            <strong class="tit_article"><span class="tit">제1조 이용정지 행위</span></strong>
            <br>&emsp;1. 헤이비글 이용 수수료 납부를 회피하거나 거부하는 등의 부정행위
                    <br>&emsp; &emsp;* 시도 및 유도하는 행위 포함
            <br>&emsp;2. 헤이비글 회원을 외부 채널로 유도, 이탈 시키는 행위
            <br>&emsp;3. 직거래, 현금/현장거래 등을 유도하거나 시도하는 행위
            <br>&emsp;4. 결제한 대상자가 아닌 다른사람으로 교체 및 참석 시키는 행위
            <br>&emsp;5. 견적과 다르거나 협의되지 않은 내용으로 추가금을 요구하는 행위
            <br>&emsp; &emsp;* 의뢰자가 요구사항을 추가한 경우는 제외
            <br>&emsp;6. 개인의 이득을 위해 결제/예약된 건을 취소하는 행위
            <br>&emsp;7. 회사가 정한 프로필 등록 가이드를 위반하는 행위
            <br>&emsp;8. 허위정보를 등록하거나 타인의 정보를 도용하는 행위
            <br>&emsp;9. 결제 및 리뷰 등을 조작하여 늘리는 행위
            <br>&emsp;10. 사실관계 왜곡, 불성실/적대적 태도 등으로 회사에 유,무형적 손해를 입히는 행위

            <br><br><br>
            <strong class="tit_article"><span class="tit">제2조 처리 규정</span></strong>
            <br>&emsp;1. 회사는 이용약관 위반 또는 이용정지 행위가 있을 경우, 서비스의 이용 제한 및 기타 필요한 조치를 취할 수 있습니다.
            <br>&emsp; &emsp;1차 적발 시 : 이용정지 15일
            <br>&emsp; &emsp;2차 적발 시 : 영구 이용정지 및 계정 삭제
            <br>&emsp;2. 제1조 1~3항의 부정거래 시 이용정지와 별개로 수수료를 배상하여야 합니다.
            <br>&emsp; &emsp;부당거래 1회 시 : 정상거래 수수료의 3배 배상
            <br>&emsp; &emsp;부당거래 2회 시 : 정상거래 수수료의 5배 배상
            <br>&emsp;3. 불가피한 사유가 있는 경우 3일 이내에 고객센터로 증빙자료를 제출해야 하며,
            <br>&emsp; &emsp;정상 행위로 인정될 경우 바로 정상 이용 가능합니다.
            <br>&emsp;4. 기한 내 증빙자료 미제출 시 해당 행위 인정으로 간주하여 영구 이용정지 처리됩니다.
            <br>&emsp;5. 제1조의 행위들로 서비스의 이미지 실추 등 유,무형적 손해가 발생한 경우
            <br>&emsp; &emsp;이용약관 제 17조 8항에 의거, 회사의 손해를 배상하여야 합니다.
            <br>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {}
    },
    computed: {},
    methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/etc.scss';
</style>
